<template>
  <div class="k-9sptci">
    <el-form
      :inline="true"
      class="k-9sptci-header"
      :model="searchForm"
      ref="searchForm"
    >
      <el-form-item label="活动期数" prop="id">
        <el-input placeholder="活动期数" v-model="searchForm.id"></el-input>
      </el-form-item>
      <el-form-item label="活动名称" prop="name">
        <el-input placeholder="活动名称" v-model="searchForm.name"></el-input>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-select v-model="searchForm.status" clearable placeholder="请选择">
          <el-option value="0" label="全部"></el-option>
          <el-option value="1" label="草稿"></el-option>
          <el-option value="2" label="发布中"></el-option>
          <el-option value="3" label="已结束"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        label="活动时间"
        prop="search_time"
        :rules="[
          {
            validator: searchTimeValidator,
          },
        ]"
      >
        <div style="display: flex; flex-direction: row">
          <el-date-picker
            v-model="searchForm.start"
            type="datetime"
            placeholder="选择日期时间"
          >
          </el-date-picker>
          至
          <el-date-picker
            v-model="searchForm.end"
            type="datetime"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="success" @click="searchClick">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary " @click="addActivity">新增</el-button>
      </el-form-item>
      <!--      <el-form-item>-->
      <!--        <el-button @click="handleReset()">重置</el-button>-->
      <!--      </el-form-item>-->
    </el-form>
    <el-table
      :data="activityTable"
      style="margin-top:20px"
      border
      @row-click="openView"
    >
      <el-table-column
        v-for="item in activityList"
        :key="item._id"
        :prop="item.prop"
        :type="item.type"
        :label="item.label"
        :width="item.width"
        :formatter="item.formatter"
      >
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="330">
        <template slot-scope="scope">
          <perButton
            perm="activity:save"
            @click.native.stop="handleEdit(scope.row)"
            >编辑</perButton
          >
          <popconfirmButton
            perm="activity:dellist"
            content="是否确认删除？"
            text="删除"
            @onConfirm="handleDelete(scope.row)"
            style="margin-left: 10px"
          ></popconfirmButton>
          <perButton
            perm="activity:list_submission"
            @click.native.stop="handleSubmission(scope.row)"
            style="margin-left: 10px"
            >投稿管理</perButton
          >
          <perButton
            perm="activity:set_best"
            @click.native.stop="handleSetBest(scope.row)"
            >设置最佳</perButton
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="k-9sptci-footer">
      <el-pagination
        @current-change="handleQuery"
        :current-page.sync="pager.pageNum"
        layout="prev, pager, next"
        :total="pager.total"
      >
      </el-pagination>
    </div>

    <el-dialog :title="'设置最佳'" :visible.sync="bestVisible">
      <el-form
        ref="bestForm"
        :model="bestData"
        label-width="80px"
        :rules="bestRules"
      >
        <el-form-item label="最佳创意" prop="best">
          <el-input
            v-model="bestData.best"
            placeholder="请输入本期最佳创意，多个由英文逗号分隔"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="changeBest">确定</el-button>
          <el-button @click="bestVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import perButton from "@/components/perm/perButton";
export default {
  components: {
    perButton,
  },
  data() {
    let self = this;
    return {
      ids: [],
      action: 0,
      pager: {
        total: 0,
        pageNum: 1,
      },
      searchForm: {
        id: "",
        name: "",
        start: "",
        end: "",
        status: "",
      },
      activityTable: [],
      activityList: [
        {
          prop: "id",
          label: "活动期数",
        },
        {
          prop: "name",
          label: "活动名称",
        },
        {
          prop: "submit_start",
          label: "开始时间",
          formatter: this.timeFormat,
        },
        {
          prop: "public_end",
          label: "结束时间",
          formatter: this.timeFormat,
        },
        {
          prop: "status",
          label: "状态",
          width: 80,
          formatter: (row) => {
            switch (row.status) {
              case "draft":
                return "草稿";
              case "public":
                return "发布中";
              case "end":
                return "已结束";
              default:
                return "";
            }
          },
        },
        {
          prop: "is_current",
          label: "使用中",
          formatter: (row) => {
            if (row.is_current) {
              return "使用中";
            } else {
              return "未使用-编辑提交后可使用";
            }
          },
        },
        {
          prop: "best",
          label: "本期最佳",
          formatter: (row) => {
            if (row.best) {
              return row.best.join(",");
            }

            return "";
          },
        },
      ],
      bestVisible: false,
      bestId: "",
      bestData: {
        best: "",
      },
      bestRules: {
        best: [
          { required: true, message: "请输入本期最佳创意", trigger: "blur" },
          {
            pattern: /^[1-9][0-9]{3,}(\,([1-9][0-9]{3,}))*$/,
            message: "请输入正确的编号，多个由英文逗号分隔",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {},
  methods: {
    searchTimeValidator(rule, value, callback) {
      if (
        this.searchForm.start &&
        this.searchForm.start != "" &&
        this.searchForm.end &&
        this.searchForm.end != ""
      ) {
        let end = new Date(this.searchForm.end);
        let start = new Date(this.searchForm.start);
        if (start > end) {
          callback(new Error("截至时间必须大于等于起始时间"));
          return;
        }
      }

      callback();
    },
    timeFormat(row, column, cellValue, index) {
      if (cellValue && cellValue.length) {
        return new Date(cellValue).format("yyyy/MM/dd hh:mm:ss");
      } else {
        return cellValue;
      }
    },
    addActivity() {
      this.removeTabByPath("/activities/add_activity");
      this.removeTabByPath("/activities/edit_activity");
      this.removeTabByPath("/activities/view_activity");
      this.$router.push({
        path: "/activities/add_activity",
        query: { r: Math.random() },
      });
    },
    handleEdit(row) {
      this.removeTabByPath("/activities/add_activity");
      this.removeTabByPath("/activities/edit_activity");
      this.removeTabByPath("/activities/view_activity");
      this.$router.push({
        path: "/activities/edit_activity",
        query: { _id: row._id },
      });
    },
    async handleDelete(row) {
      let { data, errorCode } = await this.$http.deleteActivity({
        _id: row._id,
      });

      if (errorCode != "0000") {
        return;
      }

      this.$message({
        type: "success",
        message: "删除成功",
      });

      this.bestVisible = false;
      let curRowIndex = this.activityTable.findIndex((r) => r._id == row._id);
      if (curRowIndex >= 0) {
        this.removeTabByPath("/activities/add_activity");
        this.removeTabByPath("/activities/edit_activity");
        this.removeTabByPath("/activities/view_activity");
        this.activityTable.splice(curRowIndex, 1);

        this.activityTable.forEach((r) => {
          r.is_current = r._id === data.cur_act_id;
        });
      }
    },
    openView(row) {
      this.removeTabByPath("/activities/add_activity");
      this.removeTabByPath("/activities/edit_activity");
      this.removeTabByPath("/activities/view_activity");
      this.$router.push({
        path: "/activities/view_activity",
        query: { _id: row._id },
      });
    },
    handleSubmission(row) {
      this.$router.push({
        path: "/activities/submissions",
        query: { _id: row._id },
      });
    },
    handleSetBest(row) {
      this.bestVisible = true;
      this.bestId = row._id;
      this.bestData.best = row.best.join(",");
    },
    searchClick() {
      this.$refs.searchForm.validate((v) => {});
      this.pager.pageNum = 1;
      this.handleQuery();
    },
    async handleQuery() {
      const { pageNum } = this.pager;
      const { data, errorCode } = await this.$http.activityList({
        ...this.searchForm,
        pageNum,
      });
      if (errorCode != "0000") return;
      this.activityTable = data.data;
      this.pager = data.pager;
    },
    async changeBest() {
      this.$refs.bestForm.validate(async (valid) => {
        if (valid) {
          let { data, errorCode } = await this.$http.setActivityBestSubmission({
            _id: this.bestId,
            bestStr: this.bestData.best,
          });

          if (errorCode != "0000") {
            return;
          }

          this.$message({
            type: "success",
            message: "设置成功",
          });

          this.bestVisible = false;
          let curRow = this.activityTable.find((r) => r._id == data._id);
          if (curRow) {
            curRow.best = data.best;
            this.$bus.$emit("onBestSubmisstionChanged", data);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    onActivityChanged(data) {
      let current = this.activityTable.find((r) => r._id == data._id);
      if (current) {
        if (data.is_current) {
          this.activityTable.forEach((r) => {
            r.is_current = false;
          });
        }

        current.id = data.id;
        current.name = data.name;
        current.submit_start = data.submit_start;
        current.public_end = data.public_end;
        current.is_draft = data.is_draft;
        current.best = data.best;
        current.status = data.status;
        current.is_current = data.is_current;
      } else {
        this.pager.pageNum = 1;
        this.handleQuery();
      }
    },
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ]),
  },
  async created() {
    this.handleQuery();
    this.$bus.$off("onActivityChanged", this.onActivityChanged);
    this.$bus.$on("onActivityChanged", this.onActivityChanged);
  },
  destroyed() {
    this.$bus.$off("onActivityChanged", this.onActivityChanged);
  },
};
</script>
<style lang="less" src="../less/user.less"></style>